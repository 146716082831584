import VerticalStepper from "../../components/verticalstepper/VerticalStepper";

const Home = () => {
    return (
      <div className="home" style={{ padding: '2rem' }}>
        <VerticalStepper />
      </div>
    )
  }
  
export default Home;