import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Login from './pages/login/Login';
import Home from "./pages/home/Home";
import BNavbar from "./components/bnavbar/BNavbar";
import Footer from "./components/footer/Footer";

const App = () => {
  const Layout = () => {
    return (
      <div>
        <BNavbar />
        <Home />
        <Footer />
      </div>
    );
  };

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      children: [
        {
          path: "/home",
          element: <Home />,
        }
      ],
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/bnavbar",
      element: <BNavbar />,
    },
  ]);
  
  return <RouterProvider router={router} />;
}

export default App;