import "./footer.scss"

const Footer = () => {
    const year = new Date().getFullYear;

    return (
        <footer className='site-footer'>
            <div className="inner">Powered by <a href="eruvent.com" style={{ color: 'red' }}>Eruvent Technologies LLC</a></div>
        </footer>
    )
}

export default Footer;